<template>
  <div>
    <div id="image_wrapper">
      <img :src="require('@/assets/bg.jpg')">
    </div>

    <v-overlay
      :value="true"
      :opacity="0.5"
      class="not_found_overlay"
    >
      <v-card rounded>
        <v-card-title>
          <v-spacer></v-spacer>
          <span>{{ title }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span>{{ text }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tabindex="3"
            outlined
            @click="goToHomepage"
          >
            <v-icon>mdi-home</v-icon>
            <span>&nbsp;Go to Homepage</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import menuTitle from "../components/helpers/menu_title.vue";
export default {
  name: "PageNotFound",
  props: ["previousRoute"],
  components: {
    menuTitle,
  },
  data: () => ({
    to: null,
    from: null,
    title: "404 - Page Not Found",
    text: "The page you were looking for does not exist.",
  }),
  methods: {
    goToHomepage: function () {
      const jwt = require("../helpers/jwt");
      const user = jwt.decode(localStorage.getItem("jwt")).payload;
      if (user.is_admin || user.is_manager) {
        this.$router.push({ path: "/admin" });
      } else {
        this.$router.push({ path: "/user" });
      }
    },
  },
};
</script>

<style scoped>
#image_wrapper,
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.not_found_overlay {
  backdrop-filter: blur(40px);
}
</style>